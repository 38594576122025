import React from 'react'

export default function Footer() {
  return (
    <>
    
    <div className='pb-5 pt-5' style={{backgroundColor:'#F8f0e6'}}>
    <div className='container'>
        <div className='row color-black'>
            <div className='col-md-6'>
                <img className='mb-4' style={{width:'200px'}} src='../images/shreekar-logo.png'></img>
                <p>We are team based in nashik working in the field of construction since 
                    2012, having 12 years of experience in the field of construction we have 
                    delivered more than 200 happy homes & shops</p>
            </div>
            <div className='col-md-6'>
                <h5 className='mb-3'>Contact Us</h5>
                <p>Om Services, 108, Prestige Point, Opposite Nashik Road Police Station, Nashik Road, Nashik</p>
                <hr></hr>
                <p><b className='color-yellow'>Mobile:</b> 9422265568 / 8149582934 / 9811714777</p>
                <hr></hr>
                <p><b className='color-yellow'>Email:</b> kardileamit9@gmail.com</p>
            </div>

        </div>
    </div>
    </div>
      
    </>
  )
}
