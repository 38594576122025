import React, { useEffect, useRef } from 'react';

export default function Project({ ProjectName, carouselImages, LocationSource, ProjectBeauty, Projectdescription, pdfsource, buttonName }) {
    // Ref for the container holding the elements to be animated
    const animationRef = useRef(null);

    useEffect(() => {
        const elements = animationRef.current.querySelectorAll('.slide-in-left');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                    observer.unobserve(entry.target); // Stop observing once animated
                }
            });
        }, {
            threshold: 0.0, // Trigger when 10% of the element is visible
            rootMargin: '0px 0px -10% 0px' // Adjust as needed to ensure the animation starts when almost in view
        });

        elements.forEach(element => {
            observer.observe(element);
        });

        return () => {
            elements.forEach(element => {
                observer.unobserve(element);
            });
        };
    }, []);

    return (
        <>
            <div className='container' ref={animationRef}>
                <div className='row'>
                    <div className='col-md-12 text-center mb-4 mt-4'>
                        <h1 className='color-yellow'>{ProjectName}</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div id="carouselExampleIndicators" className="carousel slide mb-4" data-ride="carousel">
                            <ol className="carousel-indicators">
                                {carouselImages.map((_, index) => (
                                    <li
                                        key={index}
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to={index}
                                        className={index === 0 ? "active" : ""}
                                    ></li>
                                ))}
                            </ol>
                            <div className="carousel-inner">
                                {carouselImages.map((image, index) => (
                                    <div
                                        key={index}
                                        className={`carousel-item ${index === 0 ? "active" : ""}`}
                                    >
                                        <img className="d-block w-100" src={image.src} alt={`Slide ${index + 1}`} />
                                    </div>
                                ))}
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                        <div className='text-center'>
                            <h4 className='mb-4'>{Projectdescription}</h4>
                            <p>{ProjectBeauty}</p>
                            <h4>Download E-Broucher</h4>
                            <a target='_blank' href={pdfsource}>
                                <button className='secondry-button mb-5 mb-2'>{buttonName} &nbsp;
                                    <i className="fa fa-download"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <h5>STRUCTURE :</h5>
                        <p className='mb-4 slide-in-left'>Earthquake resistance RCC structure.<br />
                            External wall 6′ thick & internal wall 4′ thick.</p>

                        <h5>FLOORING :</h5>
                        <p className='mb-4 slide-in-left'>Vitrified floor tile for all rooms.</p>

                        <h5>WINDOWS :</h5>
                        <p className='mb-4 slide-in-left'>Three-track powder coated Aluminium sliding window with Mosquito
                            Net & Safety Grill. All windows will be in Granite seal.</p>

                        <h5>KITCHEN:</h5>
                        <p className='mb-4 slide-in-left'>Black Granite Kitchen platform & stainless steel sink.
                            Glazed tiles up to ceiling.</p>

                        <h5>TOILET:</h5>
                        <p className='mb-4 slide-in-left'>Designer glazed tiles of standard company up to ceiling.
                            Doors frame will be in Granite.</p>

                        <h5>PAINT:</h5>
                        <p className='mb-4 slide-in-left'>External Acrylic color & internal Plastic Emulsion.
                            One designer wall in the living room.</p>

                        <h5>PLASTER :</h5>
                        <p className='mb-4 slide-in-left'>Double coat sand face External & single Coat internal plaster.</p>

                        <h5>CEILING :</h5>
                        <p className='mb-4 slide-in-left '>POP fall ceiling in the living room.</p>

                        <h5>DOORS :</h5>
                        <p className='mb-4 slide-in-left'>All internal doors will be laminated with Plywood frame.</p>

                        <h5>ELECTRIFICATION :</h5>
                        <p className='mb-4 slide-in-left'>Concealed electrification, anchor/Roma/Legrand or equivalent modular
                            switches with Poly cab wiring.</p>

                        <h5>PLUMBING :</h5>
                        <p className='mb-4 slide-in-left'>Concealed plumbing with Diverter & Shower.</p>

                        <h5>WATER TANK :</h5>
                        <p className='mb-4 slide-in-left'>Underground & Overhead water tank. Common borewell facility.
                            Automatic sensor for water tank.</p>

                        <h5 >PARKING :</h5>
                        <p className='mb-4 slide-in-left'>Allotted parking. Paved with chequered tiles.</p>

                        <h5>COMMON FEATURES :</h5>
                        <p className='mb-4 slide-in-left'>Common septic tank. Granite staircase. Waterproofing on terrace with China Mosaic. Lift with battery backup.</p>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <h2 className='mb-4'>Site Location </h2>
                        <iframe
                            src={LocationSource}
                            width="100%"
                            height="350px"
                            allowFullScreen
                            loading="lazy" className='mb-4'>
                        </iframe>
                    </div>
                </div>
            </div>
        </>
    );
}
