import './App.css';
import Navbar from './Component/navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './Component/Index';
import Contact from './Component/Contact';
import Footer from './Component/Footer';
import Ongoing from './Component/Ongoing';
import Completed from './Component/Completed';
import Padamraj from './Component/Padamraj';
import Crystal from './Component/Crystal';




function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Index />}></Route>
          <Route path='ongoing-project' element={<Ongoing />}>
          </Route>
          <Route
            path='ongoing-project/shree-padamraj-residency'
            element={<Padamraj />}
          />
          <Route path='ongoing-project/crystal-court' element={<Crystal />} />
          <Route path='completed-project' element={<Completed />}></Route>
          <Route path='contact-us' element={<Contact />}></Route>
        </Routes>
      </Router>

      <Footer></Footer>
    </>
  );
}

export default App;
