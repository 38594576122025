import React from 'react';
import TitleBar from './TitleBar';
import { data as projects } from '../Data-provider/completed-project/data';
import { Helmet } from 'react-helmet';

export default function Completed() {

  return (
    <>
    <Helmet
     title='Completed Project | Shreekar Construction'
    description='We offered 2 and 3 BHK flats and shops with the best campus amenities like a gazebo,
       kids play area and yoga space.'
    keywords='building contractor, construction site, Nashik, construction sites in nashik,
     shreekar construction, builders in nashik,construction website in nashik'
    />
      <TitleBar
        imageSrc='../images/completed/d1.jpg'
        Title='COMPLETED PROJECTS' ProjectStatus='Completed Projects'
      />

      <section className='pb-5 pt-5'>
        <div className='container'>
          <div className='row mb-4'>
            {projects.map((project) => (
              <div className='col-md-4' key={project.id}>
                <div
                  id={project.id}
                  className='carousel slide'
                  data-ride='carousel'
                >
                  <ol className='carousel-indicators'>
                    {project.images.map((_, index) => (
                      <li
                        key={index}
                        data-target={`#${project.id}`}
                        data-slide-to={index}
                        className={index === 0 ? 'active' : ''}
                      ></li>
                    ))}
                  </ol>
                  <div className='carousel-inner'>
                    {project.images.map((image, index) => (
                      <div
                        key={index}
                        className={`carousel-item ${
                          index === 0 ? 'active' : ''}`}
                      >
                        <img
                          className='d-block w-100'
                          src={image}
                          alt={`${project.title} slide ${index + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                  <a className='carousel-control-prev'
                    href={`#${project.id}`}
                    role='button'
                    data-slide='prev'>
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a className='carousel-control-next'
                    href={`#${project.id}`}
                    role='button'
                    data-slide='next' >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
                <div className='text-center mb-5'>
                  <h3 className='mt-3 mb-3'>{project.title}</h3>
                  <p className='mb-3 color-yellow'><b>{project.description}</b></p>
                  <p className='mb-4'>{project.location}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
