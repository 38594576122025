import React from 'react';
import TitleBar from './TitleBar';
import Project from './Project';
import { Helmet } from 'react-helmet';


const padamrajImages = [
  { src: '../images/shree-padamraj/padamraj-ongoing-1.jpg' },
  { src: '../images/shree-padamraj/padamraj-ongoing-2.jpg' },
  { src: '../images/shree-padamraj/padamraj-ongoing-3.jpg' },
  { src: '../images/shree-padamraj/padamraj-ongoing-5.jpg' },
  { src: '../images/shree-padamraj/padamraj-ongoing-4.jpg' },
  { src: '../images/shree-padamraj/padamraj-ongoing-6.jpg' },
  { src: '../images/shree-padamraj/padamraj-ongoing-7.jpg' },
  { src: '../images/shree-padamraj/padamraj-ongoing-8.jpg' },
  
];

const LocationSource = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3749.818808863563!2d73.80028517339771!3d19.974120823168107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeadc7c3005d1%3A0x667cb8f4c0928db0!2sPujya%20Sri%20Sri%20Ravishankar%20Marg%2C%20Nashik%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1724154075084!5m2!1sen!2sin';


export default function Padamraj() {
  return (
    
    <div>
      <Helmet
     title='Shree Padamraj Residency | Shreekar Construction'
    description='Nestled within a rapidly burgeoning locality, Padmaraj Residency stands as 
    a testament to modern living in an ever-evolving landscape. Located in close proximity 
    to the bustling Mumbai-Agra Highway, convenience and connectivity are at your fingertips.'
    keywords='building contractor, construction site, Nashik, construction sites in nashik,
     shreekar construction, builders in nashik,construction website in nashik'
    />
      <TitleBar
        Title='SHREE PADAMRAJ RESIDENCY'
        imageSrc='../images/ongoing-project/ongoing-front.jpeg'
        ProjectStatus='Ongoing Projects'
        ProjectName='Shree Padamraj Residency'
      />
      <Project
        ProjectName="SHREE PADAMRAJ RESIDENCY"
        carouselImages={padamrajImages}
        LocationSource={LocationSource}
        Projectdescription='2 & 3 bhk luxurious flats Shops, Offices, Commercial space'
        pdfsource='../Pdf/Padmraj-Brochure.pdf'
        DownloadText='Download E-Broucher'
        buttonName='Download'
        ProjectBeauty="Nestled within a rapidly burgeoning locality, Padmaraj Residency stands as a testament
to modern living in an ever-evolving landscape. Located in close proximity to the bustling
Mumbai-Agra Highway, convenience and connectivity are at your fingertips.
Upon entering the ground floor, you are warmly welcomed by the grand entrance lobby.
 A state-of-the-art gymnasium on the first floor, inviting you to embark on a journey
towards a healthier lifestyle. Moreover, practicality meets comfort with ground floor shops,
ensuring that your daily necessities are conveniently within reach,
sparing you the hassle of venturing far from home.
Welcome to a harmonious blend of convenience, connectivity, and community,
where every aspect is meticulously crafted to enhance your quality of life.
"
      />
    </div>
  );
}
