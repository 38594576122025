import React from 'react'
import TitleBar from './TitleBar'
import { Helmet } from 'react-helmet'

export default function Contact() {
  return (
    
    <>
    <Helmet
     title='Contact Us | Shreekar Construction'
    description='Get in touch with Om Services at Prestige Point, 
    Nashik Road. Contact us via phone or email for inquiries regarding our services.'
    keywords='building contractor, construction site, Nashik, construction sites in nashik,
     shreekar construction, builders in nashik,construction website in nashik'
    />
        
      <TitleBar
          imageSrc='../images/contact-us/bg-5.jpg'
          Title="CONTACT US"
          ProjectStatus='Contact Us'
        />

      <div className='container pb-5 pt-5'>
        <div class="row">
          <div class="col-md-6 mb-4">
            <div class="map-container">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3750.335075503967!2d73.83687731422688!3d19.952405929024945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdd955660421733%3A0x93ac9d362bfeb6c!2sPrestige%20Point!5e0!3m2!1sen!2sin!4v1627307563889!5m2!1sen!2sin" width="100%" height="350px" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>

          <div class="col-md-6 justify-content-end">
           
              <h5><strong>Address: </strong></h5>
              <p className='mb-4'>Om Services, 108, prestige point, Opposite Nashik Road Police Station, Nashik Road, Nashik
              </p>
              <h5><strong>Phone:</strong></h5>
              <p lassName='mb-4'>9422265568 / 8149582934 / 9811714777</p>
            <h5><strong >Email:</strong></h5>
            <p className='mb-4'><a href="mailto:kardileamit9@gmail.com"><b>kardileamit9@gmail.com</b></a></p>

<h4><strong>Open:</strong></h4>
<p className='mb-4'>Monday - Saturday&nbsp;:  10:00am - 08:00pm.</p>
             
          </div>
        </div>

      </div>

    </>
  )
}
