import React, { useState, useEffect } from 'react';
import "../Css-file/custom.css";
import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
    closeMenu(); // Ensure the menu closes after navigation
  }, [location.pathname]);

  return (
    <nav
      className={`navbar navbar-expand-sm fixed-top ${
        isScrolled ? 'navbar-scrolled' : 'navbar-light'
      }`}
    >
      <div className="container">
        <Link to='/' onClick={closeMenu}>
          <img
            style={{ width: '200px' }}
            src="../images/shreekar-logo.png"
            alt="Logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-controls="navbar1"
          aria-expanded={isMenuOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbar1">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link
                className={`nav-link Ucase color-black ${
                  location.pathname === '/' ? 'active' : ''
                }`}
                to="/"
                onClick={closeMenu}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link Ucase color-black ${
                  location.pathname === '/ongoing-project' ? 'active' : ''
                }`}
                to="/ongoing-project"
                onClick={closeMenu}
              >
               <b> Ongoing Project</b>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link Ucase color-black ${
                  location.pathname === '/completed-project' ? 'active' : ''
                }`}
                to="/completed-project"
                onClick={closeMenu}
              >
                Completed Project
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link Ucase color-black ${
                  location.pathname === '/contact-us' ? 'active' : ''
                }`}
                to="/contact-us"
                onClick={closeMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
