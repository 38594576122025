import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function Index() {
  
  

  return (
    <>
    <Helmet
     title='Home | Shreekar Construction'
    description='We are team based in nashik working in the field of construction since 2012, having 8 years
                of experience in the field of construction we have delivered more than 200 happy homes & shops'
    keywords='building contractor, construction site, Nashik, construction sites in nashik,
     shreekar construction, builders in nashik,construction website in nashik'
    />
      <header>
        <div className="header-image">
          <img
            className="header-img"
            alt="Front page"
            src="./images/padamraj-front-mini.jpeg"
          />

          <div className="front-text">
            <h4 >
              <b>OUR EXPERTISE FOR </b>
            </h4>
          </div>
          <div className="header-text">
  <h3 class="fade-up-down first-text font-big">Development. </h3>
  <h3 class="fade-up-down second-text font-big">Construction.  </h3>
          </div>
        </div>
      </header>

      <div className="background-yellow pb-4 pt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h3> Looking for best partner for your next construction works?</h3>
            </div>
            <div className="col-md-3 text-end">
             <Link to='/contact-us'> <button className="primary-button"><b>contact us</b></button></Link>
            </div>
          </div>
        </div>
      </div>
     

      <div className="why-choose-block">
      <div className="container pb-5 pt-5">
        <div className="row mb-4">
          <div className="col-md-12 fade-in">
            <h2>Why Choose Us</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mb-3 fade-in">
            <div className="row">
              <div className="col-md-2 col-2">
                <h1 className="color-yellow">1</h1>
              </div>
              <div className="col-md-10 col-10 mt-1">
                <h4>Quality</h4>
                <p>
                  Our motto is to deliver quality product, construction, and service which would satisfy the customer.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 fade-in">
            <div className="row">
              <div className="col-md-2 col-2">
                <h1 className="color-yellow">2</h1>
              </div>
              <div className="col-md-10 col-10 mt-1">
                <h4>Strategic Location</h4>
                <p>
                  Our projects are located around market places that are easily accessible with good connectivity to every corner of the city.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 fade-in">
            <div className="row">
              <div className="col-md-2 col-2">
                <h1 className="color-yellow">3</h1>
              </div>
              <div className="col-md-10 col-10 mt-1">
                <h4>Vastu Based Design</h4>
                <p>
                  We carefully study our building plans considering every aspect of Vastu Shastra and then finalize them.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mb-3 fade-in">
            <div className="row">
              <div className="col-md-2 col-2">
                <h1 className="color-yellow">4</h1>
              </div>
              <div className="col-md-10 col-10 mt-1">
                <h4>On-Time Completion</h4>
                <p>
                  We deliver projects on our committed schedule without compromising on quality.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 fade-in">
            <div className="row">
              <div className="col-md-2 col-2">
                <h1 className="color-yellow">5</h1>
              </div>
              <div className="col-md-10 col-10 mt-1">
                <h4>No Hidden Costs</h4>
                <p>
                  We maintain clarity in each and every product we sell.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 fade-in">
            <div className="row">
              <div className="col-md-2 col-2">
                <h1 className="color-yellow">6</h1>
              </div>
              <div className="col-md-10 col-10 mt-1">
                <h4>Guaranteed Service</h4>
                <p>
                  Easy loans through tie-ups with Nationalised Banks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     
    </>
  );
}
