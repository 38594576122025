import React from 'react';
import TitleBar from './TitleBar';
import Project from './Project';
import { Helmet } from 'react-helmet';

const crystalImages = [
  { src: '../images/crystal-court/crystal-court.jpeg' },
];

const LocationSource='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3749.85160315343!2d73.78276937339764!3d19.972742123213298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb0292fe23ab%3A0x9741782423f8250e!2sWadala%20-%20Pathardi%20Rd%2C%20Nashik%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1724154208071!5m2!1sen!2sin'

export default function Crystal() {
  return (
    <>
    <Helmet
     title='Crystal Court  | Shreekar Construction'
    description='We offered 2 and 3 BHK flats and shops with the best campus amenities like a gazebo,
       kids play area and yoga space.'
    keywords='building contractor, construction site, Nashik, construction sites in nashik,
     shreekar construction, builders in nashik,construction website in nashik'
    />

      <TitleBar
        imageSrc='../images/ongoing-project/ongoing-front.jpeg'
        Title='CRYSTAL COURT'
        ProjectStatus='Ongoing Projects'
        ProjectName='Crystal Court'
      />
      <Project
        ProjectName="CRYSTAL COURT"
        carouselImages={crystalImages}
        Projectdescription='2 & 3 bhk luxurious flats Shops, Offices, Commercial space'
        buttonName='Available Soon'
        LocationSource={LocationSource}

      />
    </>
  );
}
