// projectData.js

export const ongoingProjects = [
    {
      id: 'shree-padamraj-residency',
      title: 'SHREE PADAMRAJ RESIDENCY',
      description: '2 & 3 bhk luxurious flats Shops, Offices, Commercial space',
      location: 'Shri shri ravi shankar marg, behind Fame Cinema, Nashik',
      imageSrc: '../images/shree-padamraj/shree-padamraj-1.jpeg',
      altText: 'Shree Padamraj Residency',
    },
    {
      id: 'crystal-court',
      title: 'CRYSTAL COURT',
      description: '2 & 3 bhk luxurious flats Shops, Offices, Commercial space',
      location: 'Opp Viraj Society, Wadala Pathardi Road, Indira Nagar, Nashik',
      imageSrc: '../images/crystal-court/crystal-court.jpeg',
      altText: 'Crystal Court',
    },

    
   
  ];
  