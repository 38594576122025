  export const data = [
    {
      id: 'slide1',
      title: 'BUSINESS ICON',
      description: 'Shops, Offices, Commercial Space',
      location: 'Narayan Bapu Nagar, Nashik Road, Nashik',
      images: [
        'images/business-icon/business-icon_1.jpg',
        'images/business-icon/business-icon_2.jpg',
        'images/business-icon/business-icon_3.jpg',
        'images/business-icon/business-icon_4.jpg',
        'images/business-icon/business-icon_5.jpg',
        'images/business-icon/business-icon_6.jpg',
      ],
    },
    {
      id: 'omsrushti',
      title: 'OM SHREE SHRUSTI',
      description: '2 & 3 BHK Luxurious Flats',
      location:
        'Om Nagar, Opp Adhav Petrol Pump, Jail Road, Nashik road, Nashik',
      images: [
        'images/om-shree-shrushti/om-shree-shrushti_1.jpg',
        'images/om-shree-shrushti/om-shree-shrushti_2.jpg',
        'images/om-shree-shrushti/om-shree-shrushti_3.jpg',
        'images/om-shree-shrushti/om-shree-shrushti_4.jpg',
        'images/om-shree-shrushti/om-shree-shrushti_5.jpg',
        'images/om-shree-shrushti/om-shree-shrushti_6.jpg',
      ],
    },
    {
      id: 'slide2',
      title: 'OM PRAGATI',
      description: '2 & 3 BHK Luxurious Flats',
      location: 'Gandharva Nagari, Nashik Road, Nashik',
      images: [
        'images/om-pragati/om-pragati_4.jpg',
        'images/om-pragati/om-pragati_2.jpg',
        'images/om-pragati/om-pragati_3.jpg',
        'images/om-pragati/om-pragati_1.jpg',
      ],
    },
    {
      id: 'OMROWB',
      title: 'OM ROW BUNGLOW',
      description: '2 BHK Row Bunglows',
      location: 'Naraya Bapu Chowk, Jail Road, Nashik',
      images: [
        'images/om-row-bunglow/om-row-bunglow_2.jpg',
        'images/om-row-bunglow/om-row-bunglow_3.jpg',
        'images/om-row-bunglow/om-row-bunglow_1.jpg',
        'images/om-row-bunglow/om-row-bunglow_4.jpg',
        'images/om-row-bunglow/om-row-bunglow_5.jpg',
      ],
    },
    {
      id: 'slide4',
      title: 'OM SHREENAND',
      description: '2 BHK Luxurious Flats',
      location: 'Uttara Nagar, Tapovan Road, Nashik',
      images: [
        'images/om-shreenand/shreenand-real-1.jpg',
        'images/om-shreenand/shreenand-real-2.jpg',
        'images/om-shreenand/shreenand-real-3.jpg',
        'images/om-shreenand/shreenand-overview.jpg',
        'images/om-shreenand/shreenand-darkview.jpg',
        'images/om-shreenand/parkingview.jpg',
        'images/om-shreenand/layout.jpg',
      ],
    },
    {
      id: 'slide5',
      title: 'OM NAKSHTRA',
      description: '2 & 3 BHK Luxurious Flats',
      location: 'Charwak Chouk, Indira Nagar, Nashik',
      images: [
        'images/Om-Nakshtra/om-nakshtra-real (1).jpg',
        'images/Om-Nakshtra/om-nakshtra-real (2).jpg',
        'images/Om-Nakshtra/omnakshtra-front.jpg',
        'images/Om-Nakshtra/om-nakshtra-light.jpg',
        'images/Om-Nakshtra/parking.jpg',
        'images/Om-Nakshtra/overview.jpg',
        'images/Om-Nakshtra/typical-floor.jpg',
      ],
    },
    {
      id: 'slide6',
      title: 'OMKAR HEIGHT',
      description: 'Luxurious Apartments',
      location: 'Example Location, Nashik',
      images: [
        'images/omkar-height/omkar-height.jpg',
        'images/omkar-height/ground-floor-plan.jpg',
        'images/omkar-height/first-floor-plan.jpg',
        'images/omkar-height/second-floor-plan.jpg',
      ],
    },


  ];