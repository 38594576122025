import React from 'react';
import { Link } from 'react-router-dom';

function TitleBar({ imageSrc, Title, ProjectStatus, ProjectName }) {
  return (
    <div>
      <div className='header-image-other'>
        <img className='header-image-other' alt='Front page' src={imageSrc} />
        <div className='front-text-other '>
          <h1 className='text-shadow'>{Title ? Title : 'Title'}</h1>
          <hr></hr>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className='color-yellow' to="/"><b>Home</b></Link>
              </li>
              <li className={`breadcrumb-item ${!ProjectName ? 'active' : ''}`} aria-current={!ProjectName ? 'page' : undefined}>
                {/* If ProjectName is not present, use a plain <span> instead of a <Link> to indicate it's the current page */}
                {!ProjectName ? (
                  <span className="color-black">{ProjectStatus ? ProjectStatus : ''}</span>
                ) : (
                  <Link className="color-yellow" to="/ongoing-project">
                    {ProjectStatus ? ProjectStatus : ''}
                  </Link>
                )}
              </li>
              {ProjectName && (
                <li className="breadcrumb-item active" aria-current="page">
                  {ProjectName ? ProjectName : 'jah asddfk'}
                </li>
              )}
            </ol>
          </nav>

        </div>
      </div>
    </div>
  );
}

export default TitleBar;
