import React from 'react';
import { Link } from 'react-router-dom';
import TitleBar from './TitleBar';
import { ongoingProjects } from '../Data-provider/ongoing-project/ongoingdata';
import { Helmet } from 'react-helmet';

export default function Ongoing() {
  return (
    <>
    <Helmet
     title='Ongoing Projects | Shreekar Construction'
     description='We offered 2 and 3 BHK flats and shops with the best campus amenities like a gazebo,
       kids play area and yoga space.'
     keywords='building contractor, construction site, Nashik, construction sites in nashik,
     shreekar construction, builders in nashik,construction website in nashik'
    />
      <div>
        <TitleBar
          imageSrc='../images/ongoing-project/ongoing-front.jpeg'
          Title="ONGOING PROJECTS"
          ProjectStatus='Ongoing Projects'
        />

        <section className='pt-5 pb-5'>
          <div className='container'>
            <div className='row'>
              {ongoingProjects.map(project => (
                <div className='col-md-6' key={project.id}>
                  <img
                    src={project.imageSrc}
                    className='img-fluid'
                    alt={project.altText}
                  />
                  <div className='text-center mb-5'>
                    <h2 className='mt-3 mb-3'>{project.title}</h2>
                    <h6 className='mb-3 '><b>{project.description}</b></h6>
                    <h6 className='mb-4'>{project.location}</h6>
                    <div className='text-center'>
                      <Link to={`/ongoing-project/${project.id}`}>
                        <button className='primary-button'>View More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      {/* Render the nested routes here */}
    </>
  );
}
